<template>
  <div>
    <div class="container unselectable">
      <div class="row pt-3 pb-3" v-for="item in 2" :key="item">
        <div class="col-12">
          <div class="card shadow-sm">
            <div class="card-body">
              <div
                class="d-flex justify-content-between align-items-center mb-4"
              >
                <div class="box rounded">
                  <i class="ri-notification-line"></i>
                </div>
                <div>
                  <p class="mb-0 text-muted small">1 minutes ago</p>
                </div>
              </div>
              <div class="mb-4 border-bottom">
                <h5>New Consultation Request!</h5>
                <p>
                  Mr Ahmad wants to do consultation with you for E-prescriptions
                </p>
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <button class="btn border">Decline</button>
                <button
                  class="btn btn-success"
                  @click="handleConsultations(item)"
                >
                  Accept
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modal Request -->
    <!-- <div
      class="modal center"
      tabindex="-1"
      :style="
        showModal
          ? 'display: block; z-index:9999999; background-color: #4950579e;'
          : 'display: none'
      "
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content border-0 shadow-sm">
          <div class="modal-body p-4" v-if="!confimationCancel">
            <div class="mb-2 text-center">
              <h5>Accept Request?</h5>
              <p class="small">
                Mr Ahmad wants to do consultation with you for e-prescriptions
              </p>
            </div>
            <div class="d-flex justify-content-center align-items-center mb-3">
              <div class="img-container">
                <div class="rounded-circle bg-secondary text-light avatar">
                  A
                </div>
              </div>
            </div>
            <div class="text-center border-bottom">
              <h6 class="mb-1">Mr Ahmad Ali</h6>
              <div class="badge badge-light border rounded-pill fw-light mb-3">
                Paid
              </div>
            </div>
            <div class="d-flex justify-content-between align-items-center pt-3">
              <button class="btn border w-50 mr-2" @click="handleCancel">
                Decline
              </button>
              <button class="btn btn-success w-50" @click="showModal = false">
                Accept
              </button>
            </div>
          </div>
          <div class="modal-body p-4" v-else>
            <div class="mb-2 text-center border-bottom">
              <h5>Are you sure?</h5>
              <p class="small">Proceed to cancel request</p>
            </div>
            <div class="d-flex justify-content-between align-items-center pt-3">
              <button
                class="btn border w-50 mr-2"
                @click="confimationCancel = false"
              >
                No
              </button>
              <button class="btn btn-success w-50" @click="handleCancelRequest">
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import API from "./../../utils/API";

export default {
  name: "consultationRequest",
  data() {
    return {
      showModal: false,
      confimationCancel: false,
    };
  },

  methods: {
    handleCancel() {
      this.confimationCancel = true;
    },
    handleCancelRequest() {
      this.confimationCancel = false;
      this.showModal = false;
    },
    handleConsultations(_id) {
      // this.$router.push({
      //   path: `/doctor-app/consultation/${_id}`,
      // });
      let obj = {
        id: _id,
        profile_img: null,
      }
      JSBridge.showMessageInNative(JSON.stringify(obj))
    },
  },
};
</script>

<style scoped>
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.box {
  background-color: #fef3f2;
  padding: 0.25rem 0.55rem;
  color: #f97066;
}

.avatar {
  padding: 1rem 1.5rem;
  font-size: 1.3rem;
}

.fw-light {
  font-weight: 500;
}
</style>